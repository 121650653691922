<template>
  <div
    class="grid grid-cols-4 gap-3"
    aria-labelledby="help page"
    ref="helpPage"
  >
    <div class="row-start-1 col-start-1 col-span-4 lg:col-span-3">
      <div class="box-style w-full" data-cy="header-help">
        <div class="border-b border-cool-gray">
          <div class="secondhead-text">
            What exactly is the Mentor Externship Program?
          </div>
          <div class="subhead-text">
            The award-winning Mentor Externship Program is one of the most
            distinctive and innovative components of the St. Thomas School of
            Law. The program combines hands-on experience with thoughtful
            reflection as each student explores the legal profession. During
            each school year, students are paired with respected lawyers or
            judges who serve as the students’ mentors. Mentors introduce
            students to the legal profession, through observation and
            interactive experiences with a range of legal tasks and activities,
            including reviewing or drafting legal memoranda, policies and other
            documents or attending depositions, client meetings, or appellate
            arguments.
          </div>
        </div>
        <div class="flex">
          <div
            v-for="item in help_focus_area"
            :key="item.title"
            class="pt-6 w-1/2"
          >
            <div class="flex-none">
              <a
                :href="item.button_link"
                target="_blank"
                :id="item.id"
                rel="noopener noreferrer"
              >
                <img
                  :src="item.img_src"
                  alt="item.title"
                  class="w-40 h-24 mr-2 rounded-md cursor-pointer"
                />
              </a>
              <div class="flex-initial">
                <div class="thirdhead-text">{{ item.title }}</div>
                <div class="">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-start-1 lg:col-start-4 row-start-3 lg:row-start-1 col-span-2"
    >
      <div class="box-style w-full">
        <HereToHelp />
      </div>
    </div>
    <div class="row-start-2 col-span-4 lg:col-span-5 flex">
      <div class="box-style w-full">
        <div class="secondhead-text">About Working with Mentors</div>
        <div class="px-3">
          <ol class="list-decimal text-lg">
            <li class="pb-3">
              <div class="thirdhead-text">
                What should I do if I’ve reached out to my mentor and haven’t
                received a response?
              </div>
              <div class="subhead-text">
                <span class="font-bold"
                  >Try reaching out to your mentor’s support staff.</span
                >
                Many mentors rely on their support staff for help with
                scheduling. If your mentor has support staff (they’ll often tell
                you in their voicemail greeting, their website, or their email
                signature block), contact your mentor’s support staff to follow
                up or to schedule a debrief or experience.
              </div>
              <div class="subhead-text">
                <span class="font-bold">Try calling your mentor.</span> Many
                times, email is the best way to get in touch with a mentor or
                other professional, but sometimes it’s not. Your mentor may not
                be receiving your emails for whatever reason, has had health or
                other issues come up, or have other reasons they may not have
                responded via email. Whenever you don’t receive a response to an
                email, try giving your mentor a phone call.
              </div>
              <div class="subhead-text">
                If you have tried emailing
                <span class="underline">and calling</span> but have still been
                unable to connect with you mentor, please contact your program
                director.
              </div>
              <div class="text-base flex">
                <div
                  v-for="director in this.$store.state.program_directors"
                  :key="director.email"
                  class="w-1/2"
                >
                  <div class="font-bold">{{ director.name }}</div>
                  <div>{{ director.phone }}</div>
                  <div>
                    <a class="anchor" :href="`mailto:${director.email}`">{{
                      director.email
                    }}</a>
                  </div>
                  <div>{{ director.location }}</div>
                </div>
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                I haven’t been able to do much with my mentor. I think my mentor
                is too busy for me. Do you have suggestions?
              </div>
              <div class="text-base px-5">
                <ol class="list-disc">
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Stay connected with your mentor.</span
                      >
                      During your first meeting with your mentor, figure out the
                      best way to contact each other and let them know you’d
                      like to get an experience or debriefing in each month, if
                      possible. Then contact your mentor at least once a month
                      to ask what is coming up on their calendar. All of our
                      mentors have indicated an interest in mentoring this year
                      and they expect to spend 15-18 hours per year with you.
                      They do expect you to take the lead in contacting them.
                      Some mentors are more experienced and creative than
                      others, some need you to be more assertive in telling them
                      what you are interested in seeing, and some mentors rely
                      on their support staff to stay connected with you and for
                      scheduling.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Let your mentor know you are open to a variety of
                        experiences.</span
                      >
                      The experiences you identified on your Personal and
                      Professional Development Plan (PPDP) are a start, but
                      there are a wide range of experiences for which you can
                      receive credit. Look at the experiences section of the
                      Mentor Externship Manual or check out the Resources to
                      Help Find and Complete Fieldwork page of the
                      <a
                        class="anchor"
                        :href="help_focus_area[0].button_link"
                        target="_blank"
                        >Mentor Program Canvas</a
                      >
                      for ideas.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Focus on the work your mentor really does.</span
                      >
                      Not all the work lawyers do is as exciting as a trial. Ask
                      your mentor what he or she does in a typical day or week
                      and ask if you can watch or come along. Bar association,
                      firm or business meetings, corporate board meetings, and
                      client meetings can be great experiences.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold">Remember the “wild card.”</span>
                      See if your mentor has some ideas for experiences that
                      aren’t in the list of approved experiences. Perhaps your
                      mentor has a networking event, or a project or activity
                      that he or she thinks would be a good experience for the
                      two of you to do.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold">Get hands on.</span> Some mentors
                      will allow their students to do work on projects such as
                      reviewing discovery or other documents or drafting
                      documents for active cases. A mentor can also allow you to
                      do a simulated drafting assignment using a closed client
                      file. In addition to the experience time, the time spent
                      discussing the project and receiving feedback will count
                      as debriefing time. The identified experience list has a
                      number of suggestions for research and writing projects
                      that can be supervised by your mentor.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold">Maximize by preparing.</span>
                      Whenever you set up an experience with your mentor, ask
                      what preparation you might be able to do to prepare, such
                      as research, reading cases, or reading documents. This
                      enhances the value of the experience and the time you
                      spend is logged as “preparation” time.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                I don’t really like my mentor. What do I do?
              </div>
              <div class="subhead-text">
                Keep trying. In “real life,” you will encounter people from all
                walks of life with different viewpoints and different
                personalities, but you must remain professional at all times.
                Working with your mentor is a terrific opportunity to learn and
                practice your professionalism. Stay in contact with your mentor
                and see if you can work past the problems. If you would like
                guidance on how to smooth things out, contact your program
                director for assistance.
              </div>
              <div class="text-base flex">
                <div
                  v-for="director in this.$store.state.program_directors"
                  :key="director.email"
                  class="w-1/2"
                >
                  <div class="font-bold">{{ director.name }}</div>
                  <div>{{ director.phone }}</div>
                  <div>
                    <a class="anchor" :href="`mailto:${director.email}`">{{
                      director.email
                    }}</a>
                  </div>
                  <div>{{ director.location }}</div>
                </div>
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">Can I recruit my own mentor?</div>
              <div class="subhead-text">
                Yes! If an attorney or judge has already agreed to be your
                mentor, just let us know the name and contact information for
                the attorney or judge who is willing to serve as your mentor,
                and we can bring them into the program and formally pair you
                with that attorney or judge. If you would like to ask an
                attorney or judge to be your mentor, see the Mentor Program
                Canvas page Can I Recruit My Own Mentor? for more details.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                Why have I been paired with more than one mentor?
              </div>
              <div class="subhead-text">
                You have mentors who have requested to jointly mentor a student.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                How do I work with multiple mentors?
              </div>
              <div class="text-base px-5">
                <ol class="list-disc">
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Should I tell my mentors about one another?</span
                      >
                      Yes, if you are paired with a second mentor, make sure
                      both mentors know about each other. And be sure they
                      understand that you are taking advantage of opportunities
                      to supplement your experiences and benefit from more
                      mentoring.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Do I need to be in contact with both?</span
                      >
                      Yes. It is important to give both mentors the full
                      opportunity to mentor you, so plan to stay in contact with
                      both mentors monthly and have experiences and debriefings
                      with each. Neither mentor should feel they are second to
                      the other.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Do I have to do a Personal and Professional Development
                        Plan (PPDP) with each mentor?</span
                      >
                      If you meet with both mentors at the same time, you do not
                      have to do a separate PPDP for each mentor. But please
                      indicate in your PPDP comments that you are meeting with
                      both mentors at the same time. If you are meeting with
                      each mentor separately, then you will need to draft and
                      complete separate PPDPs with each mentor.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >How much of my time with both mentors counts?</span
                      >
                      What if I go over? All of it counts! Log all of your time
                      even if you go over the minimum requirements. Your Mentor
                      Log should reflect ALL you are doing and learning with
                      your mentors and in the field.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Do I have to do two Year-End Meetings?</span
                      >
                      Yes, this is similar to your PPDPs. At the end of the
                      year, when your log is complete, meet with each mentor (or
                      both together) for the year-end meeting, so they get a
                      return on their investment by seeing all you've learned,
                      and how they may have played a part in that.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
        <div class="secondhead-text">About Mentor X Log</div>
        <div class="px-3">
          <ol class="list-decimal text-lg">
            <li class="pb-3">
              <div class="thirdhead-text">
                What do the different Milestone colors signify?
              </div>
              <div class="subhead-text">
                Milestones track significant requirements for the program.
                Deadlines are color coded to help you meet them on time:
              </div>
              <div class="text-base">
                <ol class="px-5 list-disc">
                  <li class="pb-1">
                    <div>
                      Red: The deadline has been missed. Further action is
                      required to maintain good standing, unless an extension
                      has been granted.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>Yellow: The deadline is quickly approaching.</div>
                  </li>
                  <li class="pb-1">
                    <div>Green: The deadline was successfully met.</div>
                  </li>
                  <li class="pb-1">
                    <div>Grey: Future deadlines.</div>
                  </li>
                </ol>
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                What do I do if I can’t make a deadline?
              </div>
              <div class="subhead-text">
                If you know that you will be unable to meet a Mentor Externship
                requirement or deadline, please contact a program director in
                advance. Your academic good standing and reputation depend on
                it.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                Why was my fieldwork entry returned?
              </div>
              <div class="subhead-text">
                Program staff and faculty review each entry and provide feedback
                on how to revise returned entries to meet minimum requirements
                for approval. Entries must:
              </div>
              <div class="text-base">
                <ol class="px-3 list-disc">
                  <li class="pb-1">
                    <div>Be accurately categorized</div>
                  </li>
                  <li class="pb-1">
                    <div>Contain detailed descriptions</div>
                  </li>
                  <li class="pb-1">
                    <div>Include personal reflections or observations</div>
                  </li>
                  <li class="pb-1">
                    <div>Meet professional standards:</div>
                    <div class="px-5">
                      <ol class="list-disc">
                        <li class="pb-1">
                          <div>Use complete sentences</div>
                        </li>
                        <li class="pb-1">
                          <div>Carefully proofread entries</div>
                        </li>
                        <li class="pb-1">
                          <div>Meet minimum word requirements</div>
                        </li>
                      </ol>
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                How do I enter my time correctly for my fieldwork entries?
              </div>
              <div class="subhead-text">
                Track your time in 15-minute increments.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                How do I log multiple points of contact?
              </div>
              <div class="subhead-text">
                You may record individual communications in a single point of
                contact log entry or multiple communications in single point of
                contact log entry. You are entitled to log 15 minutes for each
                communication but can choose to log zero if you feel that is
                more appropriate.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                Can I receive credit for the time I put into preparing for and
                traveling to an experience or debrief, in addition to the time I
                spend at the experience or debrief?
              </div>
              <div class="subhead-text">
                You should log their time spent preparing or traveling to an
                experience or debriefing. The travel time must be reasonable and
                the preparation must be described in your fieldwork log entry.
                You do not receive travel or prep time for attending CLEs or
                Networking.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                I completed an experience and then met with my mentor for a
                debriefing immediately after. How do I log that?
              </div>
              <div class="subhead-text">
                If you have a substantive conversation with your mentor after an
                experience, you should log the experience and debrief separately
                even though you did both at the same meeting. If there is some
                overlap in time spent between the two activities, do your best
                to split your time and log the actual time spent in each
                activity. You may allocate any travel and prep time to either
                the experience or debrief, but not both.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                I went to a trial and saw several different experiences in one
                event (e.g. voir dire, opening statement, direct examination,
                etc.). How do I log that?
              </div>
              <div class="subhead-text">
                There is not a single experience called “trial” since students
                can rarely attend the entire trial. Instead, determine which
                part(s) of the trial you observed and log the part(s) you
                attended, doing your best to figure out the time for each part
                and allocating travel or prep time to just one of the log
                entries for the trial. If you are not sure what parts of the
                trial you viewed, ask your mentor or your program director.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                What activities am I not allowed to receive Mentor Externship
                fieldwork credit for?
              </div>
              <div class="text-base">
                <ol class="list-disc px-3">
                  <li class="pb-1">
                    <div>
                      <strong>Double-dipping</strong>. Any activity that
                      independently produces other credit or compensation
                      (employment/monetary, class credit, or public service
                      requirement credit) or is a required component or activity
                      of law school coursework or extracurricular activities
                      (law review symposium or similar activity, legal clinic
                      activities, course assignments).
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <strong>Law School Extracurricular Activities</strong>.
                      Attending or participating in moot court, mock trial,
                      trial advocacy, or similar events in conjunction with a
                      law school course or extracurricular activity, regardless
                      of whether academic credit is received for the activity.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <strong>Internships or Unpaid Positions</strong>. Any
                      activity that is part of a credit-earning externship,
                      internship, or an unpaid position that a student
                      identifies on their resume or otherwise represents as a
                      “volunteer” activity.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <strong>On-campus Activities</strong>. On-campus seminars,
                      lectures, or speakers, such as on-campus presentations
                      from Career and Professional Development, Student
                      Organizations, the Holloran Center, the Prolife Center,
                      and the Murphy Institute, or other groups or departments
                      that are not Continuing Legal Education (CLE) programs or
                      networking events with lawyers or judges.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <strong
                        >Activities Outside of Fall-Spring Academic Year</strong
                      >. Time logged for fieldwork activities, other than
                      Orientation, that occurred before the first day of Fall
                      semester or after the deadline for logging the year–end
                      mentor meeting.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <strong
                        >Activities Inconsistent with Course Objectives</strong
                      >. Experiences that do not involve presence or supervision
                      of a mentor, or the presence of another lawyer, judge, or
                      other approved individual; or a PPDP, year-end meeting, or
                      debriefing that is not face-to-face, without advance
                      permission of the Director.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
        <div class="secondhead-text">About Mentor Program Experiences</div>
        <div class="px-3">
          <ol class="list-decimal text-lg">
            <li class="pb-3">
              <div class="thirdhead-text">
                How much fieldwork do I have to complete each year?
              </div>
              <div class="subhead-text">
                Each year, you must complete a required number of hours and
                distinct experiences. 1L and LLM students must complete 18 hours
                of fieldwork, two debriefings with their mentor, and four
                distinct experiences with their mentor or another lawyer/judge.
                2Ls and 3Ls must complete 30 hours of fieldwork, two debriefings
                with their mentor, and five distinct experiences with their
                mentor or another lawyer/judge.
              </div>
            </li>
            <li class="pb-3">
              <div class="thirdhead-text">
                Can I get experiences without my mentor? How do I find
                experiences?
              </div>
              <div class="subhead-text">
                While many students will typically spend the majority of their
                logged hours with their mentors, you can supplement your
                fieldwork with experiences outside of your mentor relationship
                by identifying an attorney or judge whom you attended with or
                observed. Check out the Resources to Help Find and Complete
                Fieldwork page of the
                <a
                  class="anchor"
                  :href="help_focus_area[0].button_link"
                  target="_blank"
                  >Mentor Program Canvas</a
                >
                and the list below for good ways to find experiences you can
                attend without your assigned mentor that allow you to see and
                appreciate the work that lawyers and judges do or allow you to
                incorporate what you are learning about the legal system in your
                classes.
              </div>
              <div class="text-base">
                <ol class="px-5 list-disc">
                  <li class="pb-1">
                    <div>
                      <span class="font-bold">Go to the courthouse.</span> You
                      don’t need to attend a full trial to get an experience.
                      The identified experience list breaks up the trial in
                      parts, so watching different phases of the trial are
                      considered distinct experiences. The identified experience
                      list shows the variety of court proceedings that you can
                      attend for mentor credit. The Federal and State Court
                      Proceedings page of the Mentor Program Canvas has links to
                      the various court schedules.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Attend legislative hearings or meetings.</span
                      >
                      Even when the Legislature is not in session, meetings are
                      still being held. Learn more about attending legislative
                      hearings, debates, and meetings for Mentor fieldwork hours
                      and find links to the House and Senate schedules from the
                      Legislative Activities page in Mentor Program Canvas.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Attend a bar association meeting.</span
                      >
                      The various sections and committees of the Minnesota State
                      Bar Association, Ramsey County Bar Association, Hennepin
                      County Bar Association, and many other affinity bar
                      associations meet regularly and you can attend these
                      meetings for Mentor fieldwork hours. Check out the
                      Minnesota State and County Bar Associations page in Mentor
                      Program Canvas for links to the various bar associations.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Attend Continuing Legal Education (CLE) Courses.</span
                      >
                      Minnesota CLE presents programs almost every day of the
                      week in the City Center, and welcomes students to attend
                      for free. Learn more about how you can attend CLE courses
                      for Mentor fieldwork hours in the CLE Basics page of the
                      Mentor Program Canvas.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Attend city council meetings.</span
                      >
                      City council meetings provide a great opportunity to get
                      involved in your local community and you can attend
                      meetings for Mentor fieldwork hours. Check out the City
                      Council Meetings page in Mentor Program Canvas for more
                      information and links.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      Even though networking with lawyers or judges other than
                      your Mentor does not count as an experience, it does allow
                      for opportunities to get experiences with those lawyers or
                      judges. See the Mentor Program Canvas for helpful
                      Networking Tips.
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Come up with your own experiences.</span
                      >
                      If you have a particular activity that you would like to
                      do that isn’t on the experience list, talk to one of the
                      Mentor Externship Program directors to see if the activity
                      meets the objectives of the program to qualify as a
                      “director-approved experience.”
                    </div>
                  </li>
                  <li class="pb-1">
                    <div>
                      <span class="font-bold"
                        >Look at the list of approved experiences in Mentor Log
                        or the Mentor Externship Manual.</span
                      >
                      You never know what you might find. Did you know that you
                      could tour a prison or go on a ride along with a police
                      officer?
                    </div>
                  </li>
                </ol>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HereToHelp from "@/components/displays/student-displays/help/HereToHelp";
export default {
  name: "Help",
  components: { HereToHelp },
  mounted() {
    this.$refs.helpPage.focus();
  },
  data() {
    return {
      help_focus_area: [
        {
          title: "Mentor Program Canvas",
          description:
            "I'm reading to learn or need a refresher about Mentor Log features.",
          button_text: "View the Canvas",
          id: "viewCanvas",
          button_link: "https://stthomas.instructure.com/courses/49516",
          img_src: require("@/../public/imgs/canvas.png"),
        },
        {
          title: "Mentor Program Policy Manual",
          description:
            "Review the policy guide, requirements, and find experience examples for the Mentor Externship Program.",
          button_text: "View the Manual",
          id: "viewManual",
          button_link:
            "https://one.stthomas.edu/sites/schools-colleges/school-of-law/SitePageModern/33159/mentor-externship",
          img_src: require("@/../public/imgs/manual.png"),
        },
      ],
    };
  },
};
</script>

<style scoped></style>
